<template>
  <div class="content-editor">
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      class="content-editor__editor"
      :class="{ 'error--text': error }"
    />
    <div v-if="error" class="error-message mt-2">
      {{ error }}
    </div>
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  // Italic,
  // Strike,
  // Underline,
  // Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  // Link,
  // Blockquote,
  HardBreak,
  // HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        // Blockquote,
        // [
        // [
        //   Link,
        //   {
        //     openOnClick: true, // Ensure links are clickable
        //     autolink: true, // Auto-detect links
        //     linkOnPaste: true, // Enable pasting links
        //     HTMLAttributes: {
        //       target: '_blank', // Open in new tab
        //       rel: 'noopener noreferrer',
        //     },
        //   },
        // ],
        // Underline,
        // Strike,
        // Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        // Code,
        // HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      content: this.value,
    }
  },
  watch: {
    value(v) {
      this.content = v
    },
    content(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .content-editor {
  &__editor {
    .tiptap-vuetify-editor__content {
      background-color: #f1f3f4;
      min-height: 300px;
      resize: vertical;
      overflow: auto;
      transition: height 0.1s ease;
    }
  }
}

::v-deep .v-card.v-sheet {
  box-shadow: none !important;
}

.error-message {
  color: #c21e51;
  font-size: 12px;
  padding-left: 12px;
}
</style>
