<template>
  <v-dialog
    v-model="showReview"
    persistent
    max-width="540"
    content-class="review-modal"
  >
    <v-card>
      <v-card-title class="headline preview-title font-weight-bold"
        >Article Preview</v-card-title
      >
      <v-card-text class="phone-review-body">
        <div class="phone-preview">
          <div class="article-content">
            <img
              v-if="!!this.bannerDraft"
              class="article-banner"
              :src="banner"
              alt="Article Image"
            />
            <div class="article-content__slider-wrapper px-2 pb-5">
              <div
                class="article-content__slider"
                v-for="item in images"
                :key="item.url"
                :style="{ backgroundImage: `url(${item.url})` }"
              ></div>
            </div>

            <div class="article-content__title px-2 pb-3">
              <div class="article-content__label font-weight-bold pb-2">
                News
              </div>
              <div class="article-content__title-text font-weight-bold">
                {{ form.title }}
              </div>
              <div class="text-light-grey article-content__publish">
                {{ publishDateInit }}
              </div>
            </div>
            <div
              class="article-content__content px-2"
              v-html="form.content"
            ></div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text @click="$emit('close')" class="btn-close-review"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * ==================================================================================
 * Review Article
 * ==================================================================================
 **/
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { dateFormat } from '@/utils/date'

export default {
  props: {
    showReview: {
      type: Boolean,
      default: false,
    },

    form: {
      type: Object,
      default: () => {
        return null
      },
    },
    imageDrafts: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    bannerDraft: {
      type: Object,
      default: () => {
        return null
      },
    },

    publishDate: {
      type: String,
      default: () => {
        return null
      },
    },
  },

  computed: {
    banner() {
      return this.bannerDraft ? this.bannerDraft.url : ''
    },

    publishDateInit() {
      return this.publishDate
        ? dateFormat(this.publishDate, DATETIME_FORMAT.dayNameFormat)
        : dateFormat(new Date(), DATETIME_FORMAT.dayNameFormat)
    },

    images() {
      return [...this.imageDrafts]
    },
  },
}
</script>

<style lang="scss">
.review-modal {
  max-height: 95% !important;
  margin: 0;
}

.preview-title {
  padding-bottom: 23px !important;
}

.phone-review-body {
  display: flex;
  justify-content: center;
  padding: 0 10px;

  .phone-preview {
    width: 290px;
    height: 600px;
    background-image: url('../../../../assets/images/phone.png');
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .article-content {
    position: absolute;
    top: 10px;
    left: 12px;
    right: 12px;
    bottom: 10px;
    background-color: white;
    overflow-y: auto;
    padding: 0px;
    z-index: 2;
    border-radius: 35px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &__label {
      color: black;
    }

    &__publish {
      font-size: 12px;
    }

    &__title-text {
      color: black;
      font-size: 20px;
    }

    &__slider-wrapper {
      display: -webkit-box;
      flex-direction: row;
      gap: 10px;
      overflow-x: auto;

      -ms-overflow-style: none;
      scrollbar-width: 2px;
    }

    &__slider {
      height: 55px;
      width: 85px;
      border-radius: 10px;
      background-size: cover;
      background-position: center;
    }

    .article-banner {
      height: calc(266px * 0.8888);
      object-fit: cover;
      object-position: center;
    }
  }

  .article-content__slider-wrapper::-webkit-scrollbar {
    height: 3px;
  }

  .article-content__slider-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  .article-content__slider-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  .article-content__slider-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .article-content__slider-wrapper {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .article-content::-webkit-scrollbar {
    display: none;
  }

  .article-banner {
    width: 100%;
    margin-bottom: 5px;
  }

  .text-content {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
  }
}

.btn-close-review {
  font-weight: 700;
  color: black;
  margin-bottom: 23px;
  margin-right: 23px;
}
</style>
